import React from 'react';
import './styles.css';

const FadeSliderWrapper = ({ prevSlide, nextSlide, children }) => {
  return (
    <div className="fade-slider__wrapper">
      <div className="fade-slider__controls">
        <div className="arrow-1" onClick={prevSlide}>
          <i className="icon">4</i>
        </div>
        <div className="arrow-2" onClick={nextSlide}>
          <i className="icon">5</i>
        </div>
      </div>
      {children}
    </div>
  );
};

export default FadeSliderWrapper