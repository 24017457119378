import { useKeenSlider } from "keen-slider/react";
import 'keen-slider/keen-slider.min.css';
import { useState } from "react";
import './styles.css';
import { AutoScroll } from "../../../helpers/sliderPlugins";

const ImagesSlider = ({ projectName, imagesArray }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [refCallback, slider] = useKeenSlider({
    slides: {
      perView: 1,
    },
    defaultAnimation: {
      duration: 900,
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    initial: 0,
    loop: true,
  },
    [AutoScroll],
  );

  const nextSlide = () => {
    slider.current.next();
  };

  const prevSlide = () => {
    slider.current.prev();
  };

  return (
    <div className="images-slider">
      <div className="images-slider__control prev-control arrow-1" onClick={prevSlide}>
        <i className="icon">4</i>
      </div>
      <div className="images-slider__control next-control arrow-2" onClick={nextSlide}>
        <i className="icon">5</i>
      </div>
      <div className="keen-slider" ref={refCallback}>
        {imagesArray.map((image, i) => {
          return (
            <div className={`keen-slider__slide number-slide${i + 1}`} key={i}>
              <div className="images-slider__slide">
                <img
                  src={image}
                  alt={`${projectName} ${i + 1}`}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="slider-pagination">
        {loaded &&
          [...Array(slider.current.track.details.slides.length).keys(),].map((idx) => {
            return (
              <button
                aria-label={`Page ${idx + 1}`}
                key={idx}
                onClick={() => slider.current?.moveToIdx(idx)}
                className={`pagination-indicator pagination-indicator-light ${currentSlide === idx && 'active'}`}
              />
            );
          })
        }
      </div>
    </div>
  );
};

export default ImagesSlider;
