import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { BrandsGrid, SolutionsCarousel } from '../../components/global';
import { solutions } from '../../data/solutions';
import './styles.css';
import ProductsList from './components/productsList/ProductsList';
import ServicesList from './components/servicesList/ServicesList';

const SolutionsPage = () => {
  const { slug } = useParams();

  const data = solutions.find((obj) => obj.slug === slug);

  if (!data) {
    return <div className="solutions-page__header">
      <div className="solutions-page__title">
        <h1>Ruta no encontrada</h1>
      </div>
    </div>
  }

  return (
    <main>
      <div
        className="solutions-page__header"
        style={{
          backgroundImage: `url(${data.cover})`
        }}
      >
        <div className="solutions-page__title">
          <i className="icon icon-size-lg text-light">{data.icon}</i>
          <h1>{data.title}</h1>
          <h2 className="font-size-lg text-light">
            {data.subtitle}
          </h2>
          <Link to="/contacto">
            <button>Solicitar información</button>
          </Link>
        </div>
      </div>

      {slug !== 'plasticos-agricolas' && <div className="pt-container pb-container">
        <Container>
          <Row>
            <Col md={1} />
            <Col md={10}>
              <p className="font-size-md">
                {data.description}
              </p>
            </Col>
            <Col md={1} />
          </Row>
        </Container>
      </div>}

      {slug === 'plasticos-agricolas' && (
        <div className="pt-container pb-container">
          <Container>
            <Row>
              <Col md={1} />
              <Col md={10}>
                <h3 className="font-size-lg mb-4">Productos</h3>
              </Col>
            </Row>
            <Row>
              <Col md={1} />
              <Col md={5} className="flex-shrink-1">
                <p className="font-size-md">
                  {data.description}
                </p>
              </Col>
              <Col md={1} />
              <Col md={5} className="d-flex">
                <ProductsList data={data.products} />
              </Col>
            </Row>
          </Container>
        </div>
      )}

      {slug !== 'plasticos-agricolas' && (data.services || data.products) && <div className="pb-container">
        <Container>
          <Row>
            <Col md={1} />
            {data.services && <Col md={5}>
              <h3 className="font-size-lg mb-4">Servicios que ofrecemos</h3>
              <ServicesList data={data.services} />
            </Col>}
            <Col md={1} />
            {data.products && <Col md={5}>
              <h3 className="font-size-lg mb-4">Productos</h3>
              <ProductsList data={data.products} />
            </Col>}
          </Row>
        </Container>
      </div>}

      {data.brands && <BrandsGrid
        title="Somos distribuidores de las mejores marcas"
        imagesArray={data.brands}
      />}
      <SolutionsCarousel
        showAltTitle
        currentSlug={slug}
      />
    </main>
  );
};

export default SolutionsPage;