import { Col, Container, Row } from "react-bootstrap";
import './styles.css';

const BrandsGrid = ({ title, imagesArray }) => (
  <Container className="brands-container pb-container">
    <Row>
      <Col
        sm={12}
        md={{ span: 5, offset: 1 }}
        className="d-flex align-items-center
      justify-content-center justify-content-lg-start"
      >
        <h3 className="font-size-lg text-center text-lg-start me-0 me-lg-5">
          {title}
        </h3>
      </Col>
      <Col sm={12} md={6}>
        <Row className="clients-img-container">
          {
            imagesArray.map((image, i) => (
              <Col xs={6} className="d-flex justify-content-center" key={i}>
                <img
                  src={image}
                  alt=""
                />
              </Col>
            ))
          }
        </Row>
      </Col>
    </Row>
  </Container >
);

export default BrandsGrid;
