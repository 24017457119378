import React from 'react';
import './styles.css';

const ServicesList = ({ data }) => {
  return (
    <ul className="services-list">
      {
        data.map((service, i) => (
          <li key={i} className="d-flex align-items-center mb-2">
            <h4 className="font-size-xl services-list__number">
              {i + 1 > 9 ? i + 1 : `0${i + 1}`}
            </h4>
            <p className="font-size-md m-0">{service}</p>
          </li>
        ))
      }
    </ul>
  );
};

export default ServicesList