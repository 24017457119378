import { useKeenSlider } from "keen-slider/react";
import 'keen-slider/keen-slider.min.css';
import { Fragment, useState } from "react";
import { projects } from '../../../data/projects';
import './styles.css';
import { Link } from "react-router-dom";
import { AutoScroll } from "../../../helpers/sliderPlugins";

const BannerComponent = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [refCallback, slider] = useKeenSlider({
    slides: {
      perView: 1,
    },
    defaultAnimation: {
      duration: 900,
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    initial: 0,
    loop: true,
  },
    [AutoScroll],
  );

  const nextSlide = () => {
    slider.current.next();
  };

  const prevSlide = () => {
    slider.current.prev();
  };

  return (
    <Fragment>
      <div className="banner-container">
        {
          projects.map((project) => (
            <div key={project.name} className={`banner-caption ${projects[currentSlide].name === project.name && 'active'}`}>
              <h3 className="font-size-lg text-light">{project.name}</h3>
              <p className="font-size-md text-light">{project.location}</p>
              <Link to={`/proyectos/${project.slug}`}>
                <button>Ver proyecto</button>
              </Link>
            </div>
          ))
        }
        <div className="banner-control banner-control__prev arrow-1" onClick={prevSlide}>
          <i className="icon">4</i>
        </div>
        <div className="banner-control banner-control__next arrow-2" onClick={nextSlide}>
          <i className="icon">5</i>
        </div>
        <div className="keen-slider" ref={refCallback}>
          {projects.map((project, i) => (
            <div className={`keen-slider__slide number-slide${i + 1}`} key={project.slug}>
              <div className="banner-slide">
                <img
                  className=""
                  src={project.images[0]}
                  alt={project.name}
                />
              </div>
            </div>
          ))}
        </div>

        <div className="slider-pagination">
          {loaded &&
            [...Array(slider.current.track.details.slides.length).keys(),].map((idx) => {
              return (
                <button
                  aria-label={`Page ${idx + 1}`}
                  key={idx}
                  onClick={() => slider.current?.moveToIdx(idx)}
                  className={`pagination-indicator pagination-indicator-light ${currentSlide === idx && 'active'}`}
                />
              );
            })
          }
        </div>
      </div>
    </Fragment>
  );
};

export default BannerComponent;
