import { Fragment } from "react";
import BannerComponent from "./Banner";
import './styles.css';

const HomeDeveloping = () => (
  <Fragment>
    <div className="home-developing-container mb-5 display-flex-align">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-1"></div>
          <div className="col-md-12 col-lg-10">
            <div align="center">
              <p className="font-size-lg">Desarrollos</p>
              <h2 className="font-size-xl home-developing-title">
                Nuestra experiencia nos respalda
              </h2>
            </div>
          </div>
          <div className="col-md-12 col-lg-1"></div>
        </div>
      </div>
    </div>
    <BannerComponent />
  </Fragment>
);

export default HomeDeveloping;
