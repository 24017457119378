import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { projects } from '../../data/projects';
import './styles.css';

const ProjectsPage = () => {

  return (
    <main>
      <div className="projects__title">
        <h1 className="font-size-lg mb-4">Nuestros desarrollos</h1>
        <h2 className="font-size-xl">
          Contamos con más de 40 años de experiencia acumulada
        </h2>
      </div>
      <div className="projects__list mt-container">
        <Container>
          {projects.map((project, i) => (
            <Row key={project.slug}>
              <Col md={1} />
              <Col md={10}>
                <Link to={`/proyectos/${project.slug}`}>
                  <div
                    className="project-card"
                    style={{
                      backgroundImage: `url(${project.images[0]})`
                    }}
                  >
                    <div className="project-card__data">
                      <div>
                        <h3 className="font-size-lg text-light">{project.name}</h3>
                        <p className="font-size-md text-light">{project.location}</p>
                      </div>
                      <div>
                        <span className="project-card__index font-size-xl">
                          {i + 1 > 9 ? i : `0${i + 1}`}
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              </Col>
            </Row>
          ))}
        </Container>
      </div>
    </main>
  );
};

export default ProjectsPage;