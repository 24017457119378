import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ContactForm from './components/contactForm/ContactForm';
import Locations from './components/locations/Locations';
import './styles.css';

const ContactPage = () => {
  return (
    <main className="contact-page">
      <Container>
        <Row className="mb-container">
          <Col lg={{ span: 5, offset: 1 }} className="mb-5 mb-lg-0">
            <h1 className="font-size-xl text-center text-lg-start">
              ¿Cómo podemos ayudarte?
            </h1>
            <ul className="contact-list mt-5">
              <li>
                <span className="icon icon-box text-dark">7</span>
                <div>
                  <a className="d-block font-size-lg m-0" href='tel:3521257831'>352 125 7831</a>
                  <a className="font-size-lg m-0" href='tel:3521253355'>352 125 3355</a>
                </div>
              </li>
              <li>
                <span className="icon icon-box text-dark">6</span>
                <a className="font-size-lg m-0" href="mailto:himamxcotizaciones@gmail.com">
                  himamxcotizaciones@gmail.com
                </a>
              </li>
            </ul>
          </Col>
          <Col lg={5}>
            <ContactForm />
          </Col>
        </Row>
      </Container>
      <Locations />
    </main>
  );
};

export default ContactPage;