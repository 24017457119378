import { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import Copyright from "./Copyright";
import "./styles.css";

const FooterComponent = () => {
  const location = useLocation();

  return (
    <Fragment>
      <footer>
        {location.pathname !== '/contacto' && <div className="pt-container display-flex-align">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-1"></div>
              <div className="col-md-12 col-lg-5">
                <h3 className="font-size-lg text-center text-lg-start">¿Tienes dudas?</h3>
                <h3 className="font-size-lg text-center text-lg-start">Comunícate con nosotros</h3>
              </div>
              <div className="col-md-12 col-lg-5 d-flex justify-content-center justify-content-lg-end">
                <Link to="/contacto">
                  <button>contactar</button>
                </Link>
              </div>
              <div className="col-md-12 col-lg-1"></div>
            </div>
          </div>
        </div>}
        <Copyright />
      </footer>
    </Fragment>
  );
}

export default FooterComponent;
