import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Copyright from "../footer/Copyright";
import "./styles.css";

const NavComponent = () => {
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    if (showMenu) {
      document.querySelector('body').style.overflow = 'hidden';
    } else {
      document.querySelector('body').style.overflow = 'auto';
    }
  }, [showMenu]);

  const hideMenu = () => {
    setShowMenu(false);
  };

  return (
    <>
      <header>
        <div>
          <Link to="/" onClick={hideMenu}>
            <img
              className="nav-img"
              src="/assets/imagenes/nav/MarcaHIMA.png"
              alt="HIMA"
              srcSet=""
            />
          </Link>
        </div>
        <div className="menu-btn" onClick={() => setShowMenu(!showMenu)}>
          <span className="font-size-sm font-uppercase">{showMenu ? 'ocultar' : 'menú'}</span>{" "}
          <span className="icon menu-icon">{showMenu ? '2' : '1'}</span>
        </div>
      </header>
      <div className={`menu-wrapper ${showMenu ? 'show' : ''}`}>
        <Container>
          <Row>
            <Col md={1} />
            <Col md={5}>
              <h1 className="text-dark mb-4">Soluciones</h1>
              <ul className="menu-list font-size-lg">
                <li>
                  <span className="icon icon-box text-interactive">
                    8
                  </span>
                  <Link to="/soluciones/riego-agricola" onClick={hideMenu}>
                    Riego agrícola
                  </Link>
                </li>
                <li>
                  <span className="icon icon-box text-interactive">
                    9
                  </span>
                  <Link to="/soluciones/riego-residencial" onClick={hideMenu}>
                    Riego residencial
                  </Link>
                </li>
                <li>
                  <span className="icon icon-box text-interactive">
                    0
                  </span>
                  <Link to="/soluciones/sistemas-de-filtracion" onClick={hideMenu}>
                    Sistema de filtración
                  </Link>
                </li>
                <li>
                  <span className="icon icon-box text-interactive">
                    a
                  </span>
                  <Link to="/soluciones/equipos-de-bombeo" onClick={hideMenu}>
                    Equipos de bombeo
                  </Link>
                </li>
                <li>
                  <span className="icon icon-box text-interactive">
                    b
                  </span>
                  <Link to="/soluciones/tuberias-y-accesorios" onClick={hideMenu}>
                    Tuberías y accesorios
                  </Link>
                </li>
                <li>
                  <span className="icon icon-box text-interactive">
                    c
                  </span>
                  <Link to="/soluciones/plasticos-agricolas" onClick={hideMenu}>
                    Plásticos agrícolas
                  </Link>
                </li>
              </ul>
            </Col>
            <Col md={5}>
              <Link to="/nosotros" onClick={hideMenu} className="font-size-xl mb-5 d-block">
                Sobre nosotros
              </Link>
              <Link to="/proyectos" onClick={hideMenu} className="font-size-xl mb-5 d-block">
                Proyectos
              </Link>
              <Link to="/contacto" onClick={hideMenu} className="font-size-xl mb-5 d-block">
                Contacto
              </Link>
            </Col>
          </Row>
        </Container>
        <div className="menu-footer">
          <Copyright />
        </div>
      </div>
    </>
  );
};

export default NavComponent;
