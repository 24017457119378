import React from 'react';
import './styles.css';

const ProductsList = ({ data }) => {
  return (
    <ul className="products-list">
      {
        data.map((prod, i) => (
          <li className="font-size-md mb-1 products-list__item" key={i}>
            {prod}
          </li>
        ))
      }
    </ul>
  );
};

export default ProductsList