import React, { useState } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import { Col, Container, Row } from 'react-bootstrap';
import './styles.css';
import { FadeSliderWrapper } from '../../../../components/global';

const Locations = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [opacities, setOpacities] = useState([]);
  const [refCallback, slider] = useKeenSlider({
    slides: 2,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    initial: 0,
    loop: true,
    drag: false,
    detailsChanged(s) {
      // permite hacer el cambio de opacidad de los slides del carrusel
      const new_opacities = s.track.details.slides.map((slide) => slide.portion)
      setOpacities(new_opacities);
    }
  });

  const nextSlide = () => {
    slider.current.next();
  };

  const prevSlide = () => {
    slider.current.prev();
  };

  return (
    <Container>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <div className="d-flex flex-column align-items-center">
            <h2 className="font-size-xl text-center">
              Sucursales
            </h2>
          </div>
          <FadeSliderWrapper
            prevSlide={prevSlide}
            nextSlide={nextSlide}
          >
            <Row>
              <Col lg={6} className="fade-slider__left-col">
                <img
                  src="./assets/imagenes/about/Sucursales.png"
                  alt=""
                  className="fade-slider__image"
                />
              </Col>
              <Col lg={6}>
                <div className="fade-slider" ref={refCallback}>
                  <div className="fader__slide" style={{
                    opacity: opacities[0],
                    pointerEvents: opacities[0] === 1 ? 'all' : 'none',
                  }}>
                    <h3 className="font-size-lg mb-4">Pénjamo, Gto.</h3>
                    <h4 className="font-size-sm font-uppercase">Dirección</h4>
                    <p className="font-size-md">
                      Prolongación Padre Hidalgo 1498 Santa Ana Pacueco, Pénjamo, Gto. 36910.
                    </p>
                    <a href="https://goo.gl/maps/whoeQCY3YihTAaATA" target="_blank" className="font-size-sm font-uppercase text-interactive display-flex-align" rel="noreferrer">
                      Ver en el mapa <i className="icon icon-size-md ms-2">3</i>
                    </a>
                  </div>
                  <div className="fader__slide" style={{
                    opacity: opacities[1],
                    pointerEvents: opacities[1] === 1 ? 'all' : 'none',
                  }}>
                    <h3 className="font-size-lg mb-4">Numarán</h3>
                    <p className="font-size-md">Pendiente</p>
                  </div>
                  <div className="fade-slider__pagination">
                    {loaded && slider.current &&
                      [...Array(slider.current.track.details.slides.length).keys(),].map((idx) => {
                        return (
                          <button
                            aria-label={`Page ${idx + 1}`}
                            key={idx}
                            onClick={() => slider.current?.moveToIdx(idx)}
                            className={`pagination-indicator ${currentSlide === idx && 'active'}`}
                          />
                        );
                      })
                    }
                  </div>
                </div>
              </Col>
            </Row>
          </FadeSliderWrapper>
        </Col>
      </Row>
    </Container>
  )
}

export default Locations;