import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ProjectsCarousel, SolutionsCarousel } from '../../components/global';
import PhilosophySection from './components/philosophySection/PhilosophySection';
import './styles.css';

const AboutPage = () => {
  return (
    <main>
      <div className="about-screen-container mb-container">
        <div className="about-screen-title">
          <p className="font-size-lg text-light">Somos HIMA</p>
          <h1>
            Haciendo del agua tu mejor aliado
          </h1>
        </div>
      </div>
      <div className="pb-container">
        <Container>
          <Row>
            <Col lg={1} />
            <Col lg={5}>
              <h2 className="font-size-xl text-center text-lg-start">
                Sobre nosotros
              </h2>
            </Col>
            <Col lg={5}>
              <p className="font-size-md text-center text-lg-start">
                Hidráulica y maquinaria (HIMA) es una empresa 100% mexicana,
                fundada en el año 2013, con el objetivo de brindarle a nuestros
                clientes asesoría, venta, diseño, instalación y mantenimiento de
                equipos de bombeo, sistemas de riego, áreas tecnificadas y venta
                de plásticos para invernadero.
              </p>
            </Col>
            <Col lg={1} />
          </Row>
        </Container>
      </div>
      <PhilosophySection />

      <SolutionsCarousel />

      <ProjectsCarousel />

    </main>
  );
};

export default AboutPage;