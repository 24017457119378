import React, { Component } from 'react';
import HomeScreen from './components/homeScreen';
import './styles.css';
import { BrandsGrid, ProjectsCarousel, SolutionsCarousel } from '../../components/global';
class HomePage extends Component {
  render() {
    return (
      <main>
        <HomeScreen />
        <SolutionsCarousel />
        <BrandsGrid
          title="Algunas de las empresas que han confiado en nosotros"
          imagesArray={[
            '/assets/imagenes/clientes/planasa.jpg',
            '/assets/imagenes/clientes/ozblu.jpg',
            '/assets/imagenes/clientes/rsf.jpg',
            '/assets/imagenes/clientes/richel.jpg'
          ]}
        />
        <ProjectsCarousel />
      </main>
    );
  }
}

export default HomePage;