import { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import './App.css';
import { Footer, Navbar } from "./components/global";
import AboutPage from "./pages/about/AboutPage";
import ContactPage from "./pages/contact/ContactPage";
import HomePage from "./pages/home/HomePage";
import ProjectDetailsPage from "./pages/projects/projectDetails/ProjectDetailsPage";
import ProjectsPage from "./pages/projects/ProjectsPage";
import SolutionsPage from "./pages/solutions/SolutionsPage";

function App() {

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/soluciones/:slug" element={<SolutionsPage />} />
        <Route path="/proyectos/:slug" element={<ProjectDetailsPage />} />
        <Route path="/proyectos" element={<ProjectsPage />} />
        <Route path="/nosotros" element={<AboutPage />} />
        <Route path="/contacto" element={<ContactPage />} />
        <Route path="/" element={<HomePage />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
