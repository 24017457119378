export const AutoScroll = (slider) => {
  let timeout
  let mouseOver = false
  const clearNextTimeout = () => {
    clearTimeout(timeout)
  };
  const nextTimeout = () => {
    clearTimeout(timeout)
    if (mouseOver) return
    timeout = setTimeout(() => {
      slider.next()
    }, 8000);
  };
  slider.on('created', () => {
    // slider.container.addEventListener("mouseover", () => {
    //   mouseOver = true
    //   clearNextTimeout()
    // })
    // slider.container.addEventListener("mouseout", () => {
    //   mouseOver = false
    //   nextTimeout()
    // })
    nextTimeout();
  });
  slider.on('dragStarted', clearNextTimeout);
  slider.on('animationEnded', nextTimeout);
  slider.on('updated', nextTimeout);
  slider.on('destroyed', clearNextTimeout);
};