import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import GirasoloLogo from '../girasoloLogo/GirasoloLogo'

const Copyright = () => {
  return (
    <Container className="mt-container mb-5">
      <Row>
        <Col md={1} />
        <Col
          md={5}
          className="d-flex align-items-center justify-content-center justify-content-md-start"
        >
          <span className="font-size-md text-center">
            HIMA ® {new Date().getFullYear()} | Todos los derechos reservados
          </span>
        </Col>
        <Col
          md={5}
          className="d-flex align-items-center justify-content-center justify-content-md-end"
        >
          <GirasoloLogo />
        </Col>
        <Col md={1} />
      </Row>
    </Container>
  )
}

export default Copyright