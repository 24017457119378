export const projects = [
  {
    slug: 'red-sun-farms',
    name: 'Red Sun Farms',
    client: 'Red Sun Farms (Agrícola El Rosal)',
    description: 'Encargados de pozos, aguas residuales e instalaciones hidráulicas en cada una de sus sucursales en México, con más de 15 años trabajando en colaboración con esta empresa con renombre a nivel internacional, que se caracteriza por su calidad y profesionalismo en cada uno de los aspectos que la componen.',
    location: 'Guanajuato',
    images: [
      '/assets/imagenes/desarrollo/redsunfarms/redsunfarms1.jpeg',
      '/assets/imagenes/desarrollo/redsunfarms/redsunfarms2.jpeg',
      '/assets/imagenes/desarrollo/redsunfarms/redsunfarms3.jpeg',
      '/assets/imagenes/desarrollo/redsunfarms/redsunfarms4.jpeg',
      '/assets/imagenes/desarrollo/redsunfarms/redsunfarms5.jpeg',
      '/assets/imagenes/desarrollo/redsunfarms/redsunfarms6.jpeg',
    ],
  },
  {
    slug: 'planasa',
    name: 'Planasa',
    client: 'Planasa (Dolores Hidalgo)',
    description: 'Encargados de pozos, rebombeos y líneas de conducción en la sucursal de Dolores Hidalgo en México, contamos ya con 4 años trabajando en colaboración con esta empresa española.',
    location: 'Guanajuato',
    images: [
      '/assets/imagenes/desarrollo/planasa/planasa1.jpeg',
      '/assets/imagenes/desarrollo/planasa/planasa2.jpeg',
      '/assets/imagenes/desarrollo/planasa/planasa3.jpeg',
      '/assets/imagenes/desarrollo/planasa/planasa4.jpeg',
      '/assets/imagenes/desarrollo/planasa/planasa5.jpeg',
      '/assets/imagenes/desarrollo/planasa/planasa6.jpeg',
      '/assets/imagenes/desarrollo/planasa/planasa7.jpeg',
    ],
  }, {
    slug: 'ozblu-sun-farms',
    name: 'Ozblu Sun Farms',
    description: 'Encargados de pozos, rebombeos y líneas de conducción en sus múltiples sucursales en México, contamos ya con 4 años trabajando en colaboración con esta empresa con gran presencia en Latinoamérica, siendo uno de los productores de berrys más grande del continente.',
    location: 'Guanajuato',
    images: [
      '/assets/imagenes/desarrollo/ozblu/ozblu1.jpeg',
      '/assets/imagenes/desarrollo/ozblu/ozblu2.jpeg',
      '/assets/imagenes/desarrollo/ozblu/ozblu3.jpeg',
    ],
  },
  {
    slug: 'richel-group',
    name: 'Richel Group',
    description: 'Como parte del equipo de trabajo de esta gran empresa de renombre internacional específicamente en el área de instalaciones hidráulicas hemos tenido la oportunidad de colaborar en varios proyectos y en diferentes partes del país.',
    location: 'México',
    images: [
      '/assets/imagenes/desarrollo/richel/richel1.jpeg',
      '/assets/imagenes/desarrollo/richel/richel2.jpeg',
      '/assets/imagenes/desarrollo/richel/richel3.jpeg',
      '/assets/imagenes/desarrollo/richel/richel4.jpeg',
      '/assets/imagenes/desarrollo/richel/richel5.jpeg',
      '/assets/imagenes/desarrollo/richel/richel6.jpeg',
      '/assets/imagenes/desarrollo/richel/richel7.jpeg',
    ],
  }
];