import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { ImagesSlider } from '../../../components/global';
import { projects } from '../../../data/projects';
import './styles.css';

const ProjectDetailsPage = () => {

  const { slug } = useParams();

  const data = projects.find((project) => project.slug === slug);

  return (
    <main>
      <Container className="project-screen-title mb-container">
        <Row className="mb-5">
          <Col md={1} />
          <Col md={5}>
            <h1 className="font-size-xl">{data.name}</h1>
          </Col>
          <Col md={1} />
          <Col md={4}>
            <Link to="/proyectos">
              <button className="mt-3">ver todos los proyectos</button>
            </Link>
          </Col>
          <Col md={1} />
        </Row>
        <Row>
          <Col md={{ span: 5, offset: 1 }}>
            <p className="font-size-md">
              {data.description}
            </p>
          </Col>
          <Col md={{ span: 4, offset: 1 }}>
            <h6 className="font-size-sm text-uppercase">Cliente</h6>
            <p className="font-size-md">{data.client || data.name}</p>
            <h6 className="font-size-sm text-uppercase">Ubicación</h6>
            <p className="font-size-md">{data.location}</p>
          </Col>
        </Row>
      </Container>
      <ImagesSlider
        projectName={data.name}
        imagesArray={data.images}
      />
    </main>
  )
}

export default ProjectDetailsPage