
export async function sendMail(data) {
  const mailTemplate = `
      <div align="center">
        Esta persona se puso en contacto con nosotros
      </div>
      <hr>
      <div>
        <b>Nombre:</b> <br>
        ${data.nombre}
      </div>
      <div>
      <b>Empresa:</b> <br>
        ${data.empresa}
      </div>
      <div>
        <b>Email: </b> <br>
        ${data.email}
      </div>
      <div>
      <b> Telefono: </b><br>
        ${data.tel}
      </div>
      <div>
        <b> Asunto: </b> <br>
        ${data.asunto}
      </div>
      <div>
        <b> Mensaje: </b> <br>
        ${data.mensaje}
      </div>
    `;

  let headers = new Headers();
  headers.append('Content-Type', 'application/x-www-form-urlencoded');

  let urlencoded = new URLSearchParams();
  urlencoded.append('from', 'himamxcotizaciones@gmail.com');
  urlencoded.append('emailPass', '12345cotizaciones');
  urlencoded.append('to', 'himamxcotizaciones@gmail.com');
  urlencoded.append('message', mailTemplate);
  urlencoded.append('subject', 'Contacto Sitio Web HIMA');

  let requestOptions = {
    method: 'POST',
    headers,
    body: urlencoded,
    redirect: 'follow',
  };

  let res = await fetch('https://sender-mail-big-pink.herokuapp.com/sendmail', requestOptions);

  return res;
}