import React, { useState } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import { Col, Container, Row } from 'react-bootstrap';
import './styles.css';
import { FadeSliderWrapper } from '../../../../components/global';

const PhilosophySection = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [opacities, setOpacities] = useState([]);
  const [refCallback, slider] = useKeenSlider({
    slides: 3,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    initial: 0,
    loop: true,
    drag: false,
    detailsChanged(s) {
      // permite hacer el cambio de opacidad de los slides del carrusel
      const new_opacities = s.track.details.slides.map((slide) => slide.portion)
      setOpacities(new_opacities);
    },
  },
    // [AdaptiveHeight]
  );

  const nextSlide = () => {
    slider.current.next();
  };

  const prevSlide = () => {
    slider.current.prev();
  };

  return (
    <Container className="pb-container">
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <div className="d-flex flex-column align-items-center">
            <p className="font-size-lg text-center">Filosofía</p>
            <h2 className="font-size-xl text-center philosophy-title">
              Trabajar con calidad te garantiza seguridad
            </h2>
          </div>
          <FadeSliderWrapper
            prevSlide={prevSlide}
            nextSlide={nextSlide}
          >
            <div className="fade-slider__controls">
              <div className="arrow-1" onClick={prevSlide}>
                <i className="icon">4</i>
              </div>
              <div className="arrow-2" onClick={nextSlide}>
                <i className="icon">5</i>
              </div>
            </div>
            <Row>
              <Col lg={6} className="fade-slider__left-col">
                <img
                  src="/assets/imagenes/about/Misión.png"
                  alt=""
                  className="fade-slider__image"
                  style={{
                    opacity: opacities[0],
                  }}
                />
                <img
                  src="/assets/imagenes/about/Visión.png"
                  alt=""
                  className="fade-slider__image"
                  style={{
                    opacity: opacities[1],
                  }}
                />
                <img
                  src="/assets/imagenes/about/Valores.png"
                  alt=""
                  className="fade-slider__image"
                  style={{
                    opacity: opacities[2],
                  }}
                />
              </Col>
              <Col lg={6}>
                <div className={`fade-slider ${currentSlide === 2 ? 'philosophy-values' : ''}`} ref={refCallback}>
                  <div className="fader__slide" style={{
                    opacity: opacities[0],
                    pointerEvents: opacities[0] === 1 ? 'all' : 'none',
                  }}>
                    <h3 className="font-size-lg mb-4">Misión</h3>
                    <p className="font-size-md">
                      Contribuir en el éxito de nuestros clientes, creando soluciones integrales en cada una de sus necesidades en los sectores de la agricultura, construcción de sistemas hidráulicos, perforación de pozos y abastecimiento de materiales en la industria de pvc y pasticos para cultivos.
                    </p>
                  </div>
                  <div className="fader__slide" style={{
                    opacity: opacities[1],
                    pointerEvents: opacities[1] === 1 ? 'all' : 'none',
                  }}>
                    <h3 className="font-size-lg mb-4">Visión</h3>
                    <p className="font-size-md">
                      Ser una empresa líder a nivel Latinoamérica, creando soluciones hidráulicas para sus proyectos y ser la primera opción en abastecimiento de materiales de PVC y plásticos para invernaderos, brindando siempre el mejor servicio y otorgando la mejor calidad en cada proyecto.
                    </p>
                  </div>
                  <div className="fader__slide" style={{
                    opacity: opacities[2],
                    pointerEvents: opacities[2] === 1 ? 'all' : 'none',
                  }}>
                    <h3 className="font-size-lg mb-4">Valores</h3>
                    <h4 className="font-size-sm font-uppercase">Respeto por el compromiso</h4>
                    <p className="font-size-md">
                      Valoramos la confianza brindada en cada proyecto por lo cual asumimos la responsabilidad de cumplir en tiempo y forma con los planes establecidos.
                    </p>
                    <h4 className="font-size-sm font-uppercase">Honestidad</h4>
                    <p className="font-size-md">
                      La transparencia, sinceridad y franqueza, son valores que actualmente el público no solamente desea para las empresas, las exige.
                    </p>
                    <h4 className="font-size-sm font-uppercase">Esfuerzo</h4>
                    <p className="font-size-md">
                      Cada colaborador que forma parte del equipo Hima, tiene el compromiso de dar lo mejor de si en cada proyecto / trabajo que realiza.
                    </p>
                    <h4 className="font-size-sm font-uppercase">Calidad</h4>
                    <p className="font-size-md">
                      Vivimos en un mundo donde todos dicen que tienen los mejores productos del mercado. Pero una cosa es afirmarlo y la otra es garantizarlo.
                    </p>
                  </div>
                  <div className="fade-slider__pagination">
                    {loaded && slider.current &&
                      [...Array(slider.current.track.details.slides.length).keys(),].map((idx) => {
                        return (
                          <button
                            aria-label={`Page ${idx + 1}`}
                            key={idx}
                            onClick={() => slider.current?.moveToIdx(idx)}
                            className={`pagination-indicator ${currentSlide === idx && 'active'}`}
                          />
                        );
                      })
                    }
                  </div>
                </div>

              </Col>
            </Row>
          </FadeSliderWrapper>
        </Col>
      </Row>
    </Container>
  )
}

export default PhilosophySection;