import React, { useState } from 'react';
import { Col, Modal, Row, Spinner } from 'react-bootstrap';
import { sendMail } from '../../../../services/email';
import './styles.css';

const ContactForm = () => {

  const [submitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState('idle');
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    nombre: '',
    empresa: '',
    tel: '',
    email: '',
    asunto: '',
    mensaje: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!submitting) {
      setSubmitting(true);
      const res = await sendMail(formData);
      setSubmitting(false);
      setShowModal(true);
      if (res.ok) {
        setStatus('success');
        setFormData({
          nombre: '',
          empresa: '',
          tel: '',
          email: '',
          asunto: '',
          mensaje: '',
        });
        return true;
      }
      setStatus('error');
      return false;
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const {
    nombre,
    empresa,
    tel,
    email,
    asunto,
    mensaje,
  } = formData;

  return (
    <form className="contact-form" onSubmit={handleSubmit}>
      <Row>
        <Col md={6}>
          <label htmlFor="name" className="d-block font-size-sm font-uppercase">
            Nombre
            <input
              className="input font-size-md"
              type="text"
              id="name"
              name="nombre"
              value={nombre}
              onChange={handleChange}
              placeholder="Nombre *"
              required
            />
          </label>
        </Col>
        <Col md={6}>
          <label htmlFor="company" className="d-block font-size-sm font-uppercase">
            Empresa
            <input
              className="input font-size-md"
              type="text"
              id="company"
              name="empresa"
              value={empresa}
              onChange={handleChange}
              placeholder="Empresa *"
              required
            />
          </label>
        </Col>
        <Col md={6}>
          <label htmlFor="tel" className="d-block font-size-sm font-uppercase">
            Teléfono
            <input
              className="input font-size-md"
              type="tel"
              id="tel"
              name="tel"
              value={tel}
              onChange={handleChange}
              placeholder="Teléfono *"
              required
            />
          </label>
        </Col>
        <Col md={6}>
          <label htmlFor="email" className="d-block font-size-sm font-uppercase">
            Correo electrónico
            <input
              className="input font-size-md"
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={handleChange}
              placeholder="ejemplo@mail.com"
            />
          </label>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <label htmlFor="subject" className="d-block font-size-sm font-uppercase">
            Asunto
            <select
              className="input font-size-md"
              id="subject"
              name="asunto"
              value={asunto}
              onChange={handleChange}
            >
              <option value="">Selecciona uno</option>
              <option value="Riego agrícola">Riego agrícola</option>
              <option value="Riego residencial">Riego residencial</option>
              <option value="Sistema de filtración">Sistema de filtración</option>
              <option value="Equipos de bombeo">Equipos de bombeo</option>
              <option value="Tuberías y accesorios">Tuberías y accesorios</option>
              <option value="Plásticos agrícolas">Plásticos agrícolas</option>
              <option value="Otro">Otro</option>
            </select>
          </label>
        </Col>
        <Col md={12}>
          <label htmlFor="message" className="d-block font-size-sm font-uppercase">
            Mensaje
            <input
              className="input font-size-md"
              type="text"
              id="message"
              name="mensaje"
              value={mensaje}
              onChange={handleChange}
              placeholder="Cuéntanos. ¿Cómo podemos ayudarte?"
            />
          </label>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 5, offset: 7 }} className="d-flex justify-content-end">
          <button type="submit" disabled={submitting}>
            Enviar mensaje
          </button>
        </Col>
      </Row>
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        size="md"
        backdrop
        contentClassName="p-2"
      >
        <Modal.Body className="d-flex flex-column align-items-center">
          <span className="font-size-lg text-dark text-center">
            {status === 'success'
              ? 'Tu mensaje ha sido enviado.'
              : 'Ha ocurrido un error. Vuelve a intentarlo más tarde.'}
          </span>
          <button
            className="mt-3"
            onClick={handleClose}
          >
            Cerrar
          </button>
        </Modal.Body>
      </Modal>
      <Modal
        show={submitting}
        centered
        size="sm"
        backdrop={submitting ? 'static' : true}
        contentClassName="p-2"
      >
        <Modal.Body className="d-flex flex-column align-items-center">
          <Spinner
            animation="border"
            variant="secondary"
            size="lg"
          />
          <span className="font-size-lg text-dark mt-3">Cargando...</span>
        </Modal.Body>
      </Modal>
    </form>
  );
};

export default ContactForm;