import { Fragment } from "react";
import { Link } from "react-router-dom";

const HomeScreen = () => (
  <Fragment>
    <div className="home-screen-container mb-container">
      <div className="home-screen-text" align="center">
        <h1>Haz del agua tu mejor aliado</h1>
        <Link to="/nosotros">
          <button className="mt-3">descubre cómo</button>
        </Link>
      </div>
      <div className="home-screen-overlay"></div>
    </div>
  </Fragment>
);

export default HomeScreen;
