import { useKeenSlider } from 'keen-slider/react';
import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { solutions } from '../../../data/solutions';
import './styles.css';

const sliderOptions = {
  breakpoints: {
    "(min-width: 200px)": {
      slides: {
        perView: 1,
      }
    },
    "(min-width: 576px)": {
      slides: {
        perView: 2,
      }
    },
    "(min-width: 1200px)": {
      slides: {
        perView: 3,
      }
    }
  },
  initial: 0,
  loop: true,
};

const SolutionsCarousel = ({ showAltTitle, currentSlug }) => {
  const [refCallback, slider] = useKeenSlider(sliderOptions);

  const nextSlide = () => {
    slider.current.next();
  };

  const prevSlide = () => {
    slider.current.prev();
  };

  useEffect(() => {
    slider.current.update(sliderOptions);
  }, [currentSlug, slider]);

  const filtered = solutions.filter((obj) => obj.slug !== currentSlug);

  return (
    <Container className="pb-container">
      <Row>
        <Col md={1} />
        <Col md={10}>
          {!showAltTitle && <p className="font-size-lg text-center text-md-start">Soluciones</p>}
          <div className="carousel-header">
            <h2 className="font-size-xl carousel-title">
              {showAltTitle ? (
                <>Otras soluciones</>
              ) : (
                <>
                  Conoce las formas
                  <span className="d-block">en que podemos ayudarte</span>
                </>
              )}
            </h2>
            <div className="carousel-controls">
              <div className="arrow-1" onClick={prevSlide}>
                <i className="icon">4</i>
              </div>
              <div className="arrow-2" onClick={nextSlide}>
                <i className="icon">5</i>
              </div>
            </div>

          </div>
          <div className="home-solution-carousel mt-5">

            <div className="keen-slider" ref={refCallback}>
              {filtered.map((solution, i) => (
                <div className={`keen-slider__slide number-slide${i + 1}`} key={solution.slug}>
                  <Link className="solution" to={`/soluciones/${solution.slug}`}>
                    <div className="solution-img-container">
                      <i className="icon solution-icon">{solution.icon}</i>
                    </div>
                    <div>
                      <p className="font-size-lg solution-title">
                        {solution.title}
                      </p>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </Col>
        <Col md={1} />
      </Row>
    </Container>
  )
}

export default SolutionsCarousel