export const solutions = [
  {
    slug: 'riego-agricola',
    icon: '8',
    title: 'Riego agrícola',
    subtitle: 'Sistemas de riego por goteo, aspersión, superficial e hidroponía.',
    cover: '/assets/imagenes/soluciones/Riego-Agricola.jpg',
    description: 'Contamos con un departamento especializado en el diseño e instalación de sistemas de riego agrícola de baja, media y alta tecnología; tanto en campo abierto como para cultivos protegidos.',
    services: [
      'Asesoría y diseño',
      'Instalaciones y reparaciones hidráulicas',
      'Venta de productos y proyectos',
    ],
    products: [
      'Cintilla',
      'Goteros',
      'Conectores',
      'Estacas',
      'Tubing',
      'Máquinas de riego',
      'Valvurerías',
      'Sistemas operativos',
    ],
  },
  {
    slug: 'riego-residencial',
    icon: '9',
    title: 'Riego residencial',
    subtitle: 'Automatizado y semiautomiatizado.',
    cover: '/assets/imagenes/soluciones/Riego-Residencial.jpg',
    description: 'Contamos con un departamento especializado en el diseño e instalación de sistemas de riego residencial de baja, media y alta tecnología; tanto para espacios pequeños como grandes.',
    services: [
      'Asesoría y diseño',
      'Instalaciones y reparaciones hidráulicas',
      'Venta de productos y proyectos',
    ],
    products: [
      'Cintilla',
      'Goteros',
      'Conectores',
      'Aspersores',
      'Tubing',
      'Programadores de riego',
      'Válvulas de riego',
      'Sensores para el clima',
    ],
  },
  {
    slug: 'sistemas-de-filtracion',
    icon: '10',
    title: 'Sistemas de filtración',
    subtitle: 'Para uso agrícola e industrial.',
    cover: '/assets/imagenes/soluciones/Sistemas-de-Filtracion.jpg',
    description: 'Contamos con un departamento especializado en el diseño e instalación de sistemas de filtración agrícola e industrial de baja, media y alta tecnología; tanto en campo abierto como para cultivos protegidos.',
    services: [
      'Asesoría y diseño',
      'Instalación y reparación de sistemas de filtración',
      'Venta de productos y proyectos',
    ],
    products: [
      'Filtros de arena',
      'Filtros de malla',
      'Filtros de cartucho',
      'Filtros de disco',
    ],
  },
  {
    slug: 'equipos-de-bombeo',
    icon: 'a',
    title: 'Equipos de bombeo',
    subtitle: 'Centrifugas y para pozos profundos.',
    cover: '/assets/imagenes/soluciones/Equipos-de-Bombeo.jpg',
    description: 'Brindamos asesoría al momento de elegir el equipo óptimo de a cuerdo a tus necesidades. Gracias a la información recabada por nuestros expertos, es posible brindarte la mejor opción de compra. Además, instalamos con base a procedimientos y lineamientos para minimizar las probabilidades de error.',
    brands: [
      '/assets/imagenes/clientes/Groundfos.jpg',
      '/assets/imagenes/clientes/Goulds.jpg',
      '/assets/imagenes/clientes/Altamira.jpg',
      '/assets/imagenes/clientes/Suditra.jpg',
      '/assets/imagenes/clientes/ABB.jpg',
      '/assets/imagenes/clientes/Barnes.jpg',
      '/assets/imagenes/clientes/Danfoss.jpg',
      '/assets/imagenes/clientes/Honda.jpg',
      '/assets/imagenes/clientes/Rodase.jpg',
      '/assets/imagenes/clientes/Siemens.jpg',
    ],
  },
  {
    slug: 'tuberias-y-accesorios',
    icon: 'b',
    title: 'Tuberías y accesorios',
    subtitle: 'Para riego agrícola, industrial y residencial.',
    cover: '/assets/imagenes/soluciones/Tuberias-y-Accesorios.jpg',
    description: 'Contamos con las mejores marcas dentro del mercado, ofreciendo durabilidad y calidad. Cumpliendo con las normas y lineamientos establecidos, tanto en el sector agrícola, residencial e industrial.',
    brands: [
      '/assets/imagenes/clientes/Cresco.jpg',
      '/assets/imagenes/clientes/VDL.jpg',
      '/assets/imagenes/clientes/Lasco.jpg',
      '/assets/imagenes/clientes/DTC.jpg',
    ],
  },
  {
    slug: 'plasticos-agricolas',
    icon: 'c',
    title: 'Plásticos agrícolas',
    subtitle: 'Para cultivos protegidos e intemperie.',
    cover: '/assets/imagenes/soluciones/Plasticos-Agricolas.jpg',
    description: 'Contamos con las mejores marcas dentro del mercado, ofreciendo durabilidad y calidad. Cumpliendo con las normas y lineamientos establecidos, tanto en campo abierto como en cultivos protegidos.',
    products: [
      'Acolchado agrícola',
      'GroundCover',
      'Plásticos para desinfección y solarización',
      'Plásticos para invernaderos',
      'Plásticos para micro túnel y macro túnel'
    ],
  }
]